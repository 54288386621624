<template>
  <button class="btn btn-circle">
    <img src="@/assets/img/svg/arrow-back.svg" alt="arrow" width="13.47" />
  </button>
</template>

<script>
export default {
  name: "t-btn-arrow",
};
</script>
