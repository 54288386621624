import ru from "./ru.json";
import en from "./en.json";
import tat from "./tat.json";

export const defaultLocale = "ru";

export const languages = {
  ru,
  en,
  tat,
};
