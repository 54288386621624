<template>
  <div v-if="product.id === product_id" class="productPopup__popup" @click.stop>
    <div class="productPopup__img">
      <img :src="product.image" :alt="product.real_name" />
      <div class="tagProduct hit" v-if="product.is_popular">Хит</div>
    </div>
    <div class="productPopup__content">
      <div class="productPopup__body">
        <div class="productPopup__head">
          <h2 class="productPopup__title">{{ product.real_name }}</h2>
          <div class="close btn-circle" @click="$emit('isShowProduct')">X</div>
        </div>
        <div class="productPopup__descr">
          <div class="productPopup__flex">
            <div class="productPopup__text">
              {{ product.description }}
            </div>
            <div class="info btn-circle">i</div>
            <div class="values">
              <div class="values__title">Пищевая ценность на 100г</div>
              <div class="values__cols">
                <div class="values__col">
                  <div class="values__numb">{{ product.energy }}</div>
                  <div class="values__name">ккал</div>
                </div>
                <div class="values__col">
                  <div class="values__numb">{{ product.proteins }}</div>
                  <div class="values__name">белки</div>
                </div>
                <div class="values__col">
                  <div class="values__numb">{{ product.fat }}</div>
                  <div class="values__name">жиры</div>
                </div>
                <div class="values__col">
                  <div class="values__numb">
                    {{ product.carbohydrates }}
                  </div>
                  <div class="values__name">углеводы</div>
                </div>
              </div>
            </div>
          </div>
          <div class="productPopup__weigth">
            {{ Math.round(product.weight) }} г
          </div>
        </div>
        <counter-price
          :product="product"
          :isHide="true"
          @total-count-cart="totalCountCart"
        />
      </div>
      <recomended
        title="Также рекомендуем"
        :product_id="product.id"
        :isRecommended="true"
      />
    </div>
  </div>
</template>

<script>
import CounterPrice from "../counters/CounterPrice.vue";
import Recomended from "../Recomended.vue";
export default {
  components: { CounterPrice, Recomended },
  name: "t-popup-product",
  props: ["product", "product_id"],
};
</script>

<style lang="scss" scoped>
.values {
  visibility: hidden;
  opacity: 0;
}
.info {
  &:hover,
  &:focus {
    ~ .values {
      visibility: visible;
      opacity: 1;
      transition: 0.2s ease 0.2s;
    }
  }
}
</style>
