<template>
  <div class="recomended">
    <h3 class="recomended__title">{{ title }}</h3>
    <div class="products">
      <div
        class="products__product"
        v-for="product in products"
        :key="product.id"
      >
        <div class="products__img">
          <img
            :src="
              product.image ? product.image : require(`@/assets/img/${nophoto}`)
            "
            :alt="product.real_name"
          />
        </div>
        <div class="products__body">
          <h4 class="products__title">
            {{
              product.real_name.length > 30
                ? product.real_name.substr(0, 30) + "..."
                : product.real_name
            }}
          </h4>
          <counter-price :product="product" :isHide="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";
import CounterPrice from "@/components/counters/CounterPrice.vue";

export default {
  name: "t-recomended",
  components: { CounterPrice },
  data() {
    return {
      products: [],
      nophoto: "nophoto.jpg",
    };
  },
  props: ["title", "product_id", "isRecommended"],
  methods: {
    plusToCart(product) {
      const array = JSON.parse(localStorage.getItem("cart")) ?? [];
      const elem = array.find((item) => item.id === product.id);
      elem.count++;
      localStorage.setItem("cart", JSON.stringify(array));
      product.count = elem.count;
    },
    minusToCart(product) {
      const array = JSON.parse(localStorage.getItem("cart")) ?? [];
      const elem = array.find((item) => item.id === product.id);
      const index = array.findIndex((item) => item.id === product.id);
      if (elem.count > 1) {
        elem.count--;
      } else {
        array.splice(index, 1);
        elem.count = null;
      }
      localStorage.setItem("cart", JSON.stringify(array));
      product.count = elem.count;
    },
    addToCart(product) {
      console.log(product);
      const array = JSON.parse(localStorage.getItem("cart")) ?? [];
      const elem = array.find((item) => item.id === product.id);
      product.count = !null;
      if (elem) {
        elem.count++;
      } else {
        let productItem = {
          id: product.id,
          name: product.real_name,
          description: product.description,
          price: product.price,
          is_popular: true,
          image: product.image,
          video: null,
          picshot: null,
          thumbnail: product.thumbnail,
          modifiers: [],
          modifiers_as_hint: false,
          energy: product.energy,
          weight: product.weight,
          articul: null,
          weight_measure: "gr",
          fat: product.fat,
          proteins: product.proteins,
          carbohydrates: product.carbohydrates,
          intro_text: product.intro_text,
          categoriesId: product.categories.id,
          categoriesRealName: product.categories.real_name,
          categoriesImage: product.categories.image,
          is_favourite: false,
          count: 1,
        };
        array.push(productItem);
      }
      localStorage.setItem("cart", JSON.stringify(array));
    },
    showProductsRec() {
      //рекомендованные товары
      axios
        .get(
          `https://admin.tcode.online/api/projects/tubatay/recommended/to-product/?product_id=${this.product_id}`
        )
        .then((response) => {
          console.log(response.data);
          this.products = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showProducts() {
      //Товары
      axios
        .get(api + `products`)
        .then((response) => {
          console.log(response.data);
          this.products = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.isRecommended ? this.showProductsRec() : this.showProducts();
  },
};
</script>

<style lang="scss" scoped>
.recomended {
  padding-top: 20px;
  @media (max-width: 1440px) {
    padding-top: 20px;
  }
  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 30.01px;
    color: #000;
  }
}
.products {
  display: flex;
  padding: 20px 0 30px;
  overflow-y: hidden;
  .block-counter {
    justify-content: space-between;
    .add-to-cart {
      width: 46px;
      height: 46px;
      border-radius: 15px;
    }
  }
  &__product {
    display: flex;
    height: 176px;
    width: 448px;
    flex: 0 0 auto;
    box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
    border-radius: 20px;
    overflow: hidden;
    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  &__img {
    width: 176.1px;
    height: 176px;
    border-radius: 15px 0 0 15px;
    overflow: hidden;
    flex: 0 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__body {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
}
</style>
