const host = window.location.href;
let cafe_slug = host.split("/");
cafe_slug = cafe_slug[2].split(".")[0];

if (host.indexOf("localhost") != -1) {
  cafe_slug = "univer";
}

//export const api = "https://tcode.online/api/" + cafe_slug + "/";
export const api =
  "https://admin.tcode.online/api/projects/tubatay/shops/" + cafe_slug + "/";
