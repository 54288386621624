<template>
  <div class="counter">
    <button
      class="counter__minus"
      @click="
        $emit('counterCart', item.id, 'decrease'), $emit('totalCountCart')
      "
    >
      -
    </button>
    <span class="counter__counts">{{ item.count }}</span>
    <button
      class="counter__plus"
      @click="
        $emit('counterCart', item.id, 'increase'), $emit('totalCountCart')
      "
    >
      +
    </button>
  </div>
</template>

<script>
export default {
  name: "t-counter",
  props: ["item"],
  methods: {
    counterCart() {
      this.$emit("counterCart");
    },
    totalCountCart() {
      this.$emit("totalCountCart");
    },
  },
};
</script>
