<template>
  <div class="not-working">
    <img :src="require(`@/assets/img/` + img)" :alt="img" />
  </div>
</template>

<script>
export default {
  name: "t-not-working",
  data() {
    return {
      img: `not-working.jpg`,
    };
  },
};
</script>

<style lang="scss">
@media (max-width: 991px) {
  .not-working {
    height: 100vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
