<template>
  <div class="banner">
    <img :src="require(`@/assets/img/` + img + `.png`)" :alt="img" />
  </div>
</template>

<script>
export default {
  name: "t-banner",
  data() {
    return {
      img: `breakfast`,
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
