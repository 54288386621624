<template>
  <div class="logo">
    <router-link :to="`/`">
      <img :src="require(`@/assets/img/svg/` + img + svg)" :alt="img" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "t-logo",
  data() {
    return {
      img: `logo`,
      svg: `.svg`,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
.logo {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  a {
    display: block;
    @media (max-width: $ms3) {
      width: 150px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
