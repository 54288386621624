import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Logo from "./components/Logo";
import Header from "./components/Header";
import OrdersHall from "./components/OrdersHall";
import ShowPopup from "./components/ShowPopup";
import MenuItems from "./components/MenuItems";
import BtnArrow from "./components/BtnArrow";
import Banner from "./components/Banner";

import { languages } from "./i18n";
import { defaultLocale } from "./i18n";
import { createI18n, useI18n } from "vue-i18n";

const localeStorageLang = localStorage.getItem("lang");

const messages = Object.assign(languages);
const i18n = createI18n({
  legacy: false,
  locale: localeStorageLang || defaultLocale,
  fallbackLocale: "ru",
  messages,
});

const app = createApp(App, {
  setup() {
    const { t } = useI18n();
    return { t };
  },
});
app.component("t-banner", Banner);
app.component("t-logo", Logo);
app.component("t-header", Header);
app.component("t-orders-hall", OrdersHall);
app.component("t-show-popup", ShowPopup);
app.component("t-menu-items", MenuItems);
app.component("t-btn-arrow", BtnArrow);
app.use(router);
app.use(i18n);
app.mount("#app");
