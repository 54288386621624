<template>
  <div class="auth" v-if="isAuth">
    <div class="auth__contain" @click.stop v-if="isSendCode">
      <div class="auth__body">
        <div class="auth__close" @click="$emit('closeAuth')">
          <img :src="require(`@/assets/img/` + close)" :alt="close" />
        </div>
        <div class="auth-sign">
          <h2 class="auth-sign__title">
            {{ authTitle }}
          </h2>
          <div class="auth-sign__img" v-if="authSign">
            <img :src="require(`@/assets/img/` + img)" :alt="img" />
          </div>
        </div>
        <div class="phone-input" v-if="inputNumbers">
          <div
            class="phone-input__phone"
            :class="{
              notvalid: phoneValidation.phone,
              valid: phoneValidation.phone === false,
            }"
            v-if="phoneInput"
          >
            <span>+7</span>
            <input
              type="text"
              v-mask="'(###) ### ##-##'"
              name="phone"
              placeholder="(___) ___-__-__"
              id="phone"
              v-model.trim="phone"
              @input="inputPhone"
            />
          </div>
          <div class="phone-input__code" v-if="codeInput">
            <div class="phone-input__post-number">
              <span>Отправили код СМС-сообщением на номер</span>
              <span>+7 {{ phone }}</span>
            </div>
            <div class="circles">
              <div
                class="circles__item"
                v-for="(number, index) in 4"
                :key="index"
              >
                <div
                  class="circles__number"
                  v-if="code[index]"
                  :class="{
                    notvalid: codeValidation.code,
                    valid: codeValidation.code === false,
                  }"
                >
                  {{ code[index] }}
                </div>
                <div class="circles__hidden" v-else></div>
              </div>
            </div>
            <input
              type="hidden"
              v-mask="'# # # #'"
              name="code"
              placeholder="* * * *"
              id="code"
              v-model.trim="code"
              @input="inputCode"
            />
          </div>
          <div
            class="phone-input__keys phone-rows"
            v-if="phoneInput"
            @click="inputPhone"
          >
            <div class="phone-row">
              <button class="phone-input__key key">1</button>
              <button class="phone-input__key key">2</button>
              <button class="phone-input__key key">3</button>
            </div>
            <div class="phone-row">
              <button class="phone-input__key key">4</button>
              <button class="phone-input__key key">5</button>
              <button class="phone-input__key key">6</button>
            </div>
            <div class="phone-row">
              <button class="phone-input__key key">7</button>
              <button class="phone-input__key key">8</button>
              <button class="phone-input__key key">9</button>
            </div>
            <div class="phone-row">
              <button class="btn btn-green btn-clear key">
                <img
                  src="@/assets/img/svg/delete.svg"
                  alt="delete"
                  class="delete"
                />
              </button>
              <button class="phone-input__key key">0</button>
              <button class="btn btn-green key">
                <img
                  src="@/assets/img/svg/clear.svg"
                  alt="clear"
                  class="clear"
                />
              </button>
            </div>
          </div>
          <div
            class="phone-input__keys phone-rows"
            v-if="codeInput"
            @click="inputCode"
          >
            <div class="phone-row">
              <button class="phone-input__key key">1</button>
              <button class="phone-input__key key">2</button>
              <button class="phone-input__key key">3</button>
            </div>
            <div class="phone-row">
              <button class="phone-input__key key">4</button>
              <button class="phone-input__key key">5</button>
              <button class="phone-input__key key">6</button>
            </div>
            <div class="phone-row">
              <button class="phone-input__key key">7</button>
              <button class="phone-input__key key">8</button>
              <button class="phone-input__key key">9</button>
            </div>
            <div class="phone-row">
              <button class="btn btn-green btn-clear key">
                <img
                  src="@/assets/img/svg/delete.svg"
                  alt="delete"
                  class="delete"
                />
              </button>
              <button class="phone-input__key key">0</button>
              <button class="btn btn-green key">
                <img
                  src="@/assets/img/svg/clear.svg"
                  alt="clear"
                  class="clear"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="auth-success" v-if="authSuccess">
          <h2 class="auth-success__title">{{ success }}</h2>
          <div class="auth-success__phone">
            <p class="auth-success__our-tel">Ваш телефон</p>
            <div class="auth-success__input-tel">+7 {{ phone }}</div>
          </div>
        </div>
      </div>
      <div class="auth__bottom">
        <div class="auth__buttons" v-if="authSign">
          <button class="btn btn-bgn" @click="$emit('closeAuth')">Нет</button>
          <button class="btn btn-green" @click="showPhoneInput">Да</button>
        </div>
        <div class="auth__buttons" v-if="inputNumbers">
          <button class="btn btn-bgn" @click="$emit('closeAuth')">Назад</button>
          <button
            class="btn btn-green btn-add-phone"
            v-if="phoneInput"
            @click="validPhone2()"
          >
            Продолжить
          </button>
          <button
            class="btn btn-green btn-add-phone"
            v-if="codeInput"
            @click="validCode()"
          >
            Продолжить
          </button>
        </div>
        <div class="auth__buttons" v-if="authSuccess">
          <button class="btn btn-green" @click="$emit('closeAuth')">
            Продолжить
          </button>
        </div>
      </div>
    </div>
    <div class="name-input" @click.stop v-if="isInputName">
      <div class="auth__close" @click="$emit('closeAuth')">
        <img :src="require(`@/assets/img/` + close)" :alt="close" />
      </div>
      <h2 class="name-input__title">Как вас зовут?</h2>
      <div class="name-input__input">
        <p>Пожалуйста, введите свое имя</p>
        <input
          type="text"
          name="name"
          placeholder="Имя"
          v-model="name"
          autocomplete="off"
        />
      </div>
      <div class="name-input__block">
        <div class="name-input__container">
          <div class="name-input__body">
            <div class="keyboard__keys" @click="typeLetter">
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="(number, index) in 9"
                  :key="index"
                >
                  {{ number }}
                </button>
                <button class="keyboard__key key">0</button>
                <button class="btn btn-lightgreen btn-long key">
                  {{ $t("cart.btn_clear") }}
                </button>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in lettersLang.row1"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <button class="btn btn-lightgreen btn-long2 key">
                  <img
                    src="@/assets/img/svg/clear.svg"
                    alt="clear"
                    class="clear"
                  />
                </button>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in lettersLang.row2"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <button class="btn btn-lightgreen btn-long2 key">
                  <img
                    src="@/assets/img/svg/space.svg"
                    alt="space"
                    class="space"
                  />
                </button>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in lettersLang.row3"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <button
                  class="btn btn-lightgreen btn-long key"
                  @click="changeLang"
                >
                  {{ lang }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="name-input__btn">
        <button class="btn btn-green" @click="sendName">
          Завершить регистрацию
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import { api } from "@/api/api";

export default {
  name: "t-popup-auth",
  data() {
    return {
      isSendCode: true,
      isInputName: false,
      isAuth: true,
      authSign: true,
      phoneInput: false,
      authSuccess: false,
      codeInput: false,
      inputNumbers: false,
      success: "Неверный промокод! Попробуйте ещё раз!",
      phoneValidation: {
        phone: null,
      },
      phone: "",
      promocodeInput: "",
      authTitle: "Хотите получать блюда\n за 1₽?",
      codeValidation: {
        code: null,
      },
      code: "",
      promocodeValidation: {
        promocode: null,
      },
      close: "close.png",
      img: `dishes.png`,
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name") ?? "",
      lettersRu: {
        row1: [
          { id: 1, char: `й` },
          { id: 2, char: `ц` },
          { id: 3, char: `у` },
          { id: 4, char: `к` },
          { id: 5, char: `е` },
          { id: 6, char: `н` },
          { id: 7, char: `г` },
          { id: 8, char: `ш` },
          { id: 9, char: `щ` },
          { id: 10, char: `з` },
          { id: 11, char: `х` },
          { id: 12, char: `ъ` },
        ],
        row2: [
          { id: 1, char: `ф` },
          { id: 2, char: `ы` },
          { id: 3, char: `в` },
          { id: 4, char: `а` },
          { id: 5, char: `п` },
          { id: 6, char: `р` },
          { id: 7, char: `о` },
          { id: 8, char: `л` },
          { id: 9, char: `д` },
          { id: 10, char: `ж` },
          { id: 11, char: `э` },
        ],
        row3: [
          { id: 1, char: `я` },
          { id: 2, char: `ч` },
          { id: 3, char: `с` },
          { id: 4, char: `м` },
          { id: 5, char: `и` },
          { id: 6, char: `т` },
          { id: 7, char: `ь` },
          { id: 8, char: `б` },
          { id: 9, char: `ю` },
          { id: 10, char: `_` },
        ],
      },
      lettersEng: {
        row1: [
          { id: 1, char: `a` },
          { id: 2, char: `b` },
          { id: 3, char: `c` },
          { id: 4, char: `d` },
          { id: 5, char: `e` },
          { id: 6, char: `f` },
          { id: 7, char: `g` },
          { id: 8, char: `h` },
          { id: 9, char: `i` },
          { id: 10, char: `j` },
          { id: 11, char: `k` },
          { id: 12, char: `l` },
        ],
        row2: [
          { id: 1, char: `m` },
          { id: 2, char: `n` },
          { id: 3, char: `o` },
          { id: 4, char: `p` },
          { id: 5, char: `q` },
          { id: 6, char: `r` },
          { id: 7, char: `s` },
          { id: 8, char: `t` },
          { id: 9, char: `u` },
          { id: 10, char: `v` },
          { id: 11, char: `w` },
        ],
        row3: [
          { id: 1, char: `x` },
          { id: 2, char: `y` },
          { id: 3, char: `z` },
        ],
      },
      lang: "ENG",
    };
  },
  methods: {
    changeLang() {
      this.lang === "ENG" ? (this.lang = "РУС") : (this.lang = "ENG");
    },
    typeLetter() {
      if (event.target.className !== "keyboard__row") {
        let key = event.target.innerHTML || event.target.className;
        if (key === "clear")
          this.name = this.name.substr(0, this.name.length - 1);
        else if (key === "Очистить") this.name = "";
        else if (key === "space") this.name += " ";
        else if (key === "ENG" || key === "РУС") this.changeLang;
        else this.name += key.toUpperCase();
      }
    },
    showPhoneInput() {
      this.authSign = false;
      this.inputNumbers = true;
      this.phoneInput = true;
      this.authTitle = "Введите номер телефона";
    },
    cancelClose() {
      this.isPopupKeyboad = false;
      this.message = "";
    },
    inputPhone() {
      if (
        event.target.className !== "keyboard__keys" &&
        event.target.className !== "phone-row" &&
        event.target.className !== "btn-add-phone"
      ) {
        let number = event.target.innerHTML || event.target.className;
        if (number === "clear")
          this.phone = this.phone.substr(0, this.phone.length - 1);
        else if (number === "delete") this.phone = "";
        else {
          this.phone.length > 9
            ? (this.phone = this.phone.substr(0, this.phone.length))
            : (this.phone += number);
        }
      }
      this.phoneValidation.phone = !/^[\d]{10}$/.test(this.phone)
        ? true
        : false;
    },
    inputCode() {
      if (
        event.target.className !== "keyboard__keys" &&
        event.target.className !== "phone-row"
      ) {
        let num = event.target.innerHTML || event.target.className;
        if (num === "clear")
          this.code = this.code.substr(0, this.code.length - 1);
        else if (num === "delete") this.code = "";
        else {
          this.code.length > 3
            ? (this.code = this.code.substr(0, this.code.length))
            : (this.code += num);
        }
      }
      this.codeValidation.code = !/^[\d]{4}$/.test(this.code) ? true : false;
    },
    addPhone() {
      axios
        .post(`https://admin.tcode.online/api/projects/tubatay/auth/phone/`, {
          phone: `+7${this.phone}`,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addCode() {
      axios
        .post(
          `https://admin.tcode.online/api/projects/tubatay/auth/verification/`,
          {
            phone: `+7${this.phone}`,
            code: this.code,
          }
        )
        .then((response) => {
          console.log(response.data);
          localStorage.setItem("token", response.data.token);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addName() {
      axios
        .post(`https://admin.tcode.online/api/projects/tubatay/profile/`, {
          name: this.name,
          headers: {
            Authorization: "Token " + this.token,
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validPhone2() {
      if (!this.phoneValidation.phone && this.phone.length > 0) {
        this.addPhone();
        this.phoneInput = false;
        this.codeInput = true;
        this.authTitle = "Введите код";
      } else {
        this.phoneInput = true;
        this.codeInput = false;
        this.authTitle = "Введите номер телефона";
      }
    },
    validCode() {
      if (!this.codeValidation.code && this.code.length === 4) {
        this.addCode();
        this.isInputName = true;
        this.isSendCode = false;
      } else {
        this.isInputName = false;
        this.isSendCode = true;
      }
    },
    sendName() {
      if (this.name.length >= 2) {
        this.addName();
        this.isAuth = false;
        this.$emit("closeAuth");
        window.location.reload();
      }
    },
  },
  watch: {
    name: function () {
      localStorage.setItem("name", this.name);
    },
  },
  mounted() {
    this.token ? (this.isAuth = false) : (this.isAuth = true);
    console.log(this.name);
  },
  computed: {
    lettersLang() {
      return this.lang === "ENG" ? this.lettersRu : this.lettersEng;
    },
  },
};
</script>

<style lang="scss">
.name-input {
  background: #fff;
  border-radius: 20px;
  padding: 50px 30px 30px;
  position: relative;
  &__container {
    padding: 0;
  }
  &__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 65.47px;
    color: #000;
    text-align: center;
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 20px;
      line-height: 24px;
      color: #000;
      text-align: center;
      margin-top: 1rem;
    }
    input {
      width: 100%;
      max-width: 512px;
      height: 80px;
      border-radius: 15px;
      border: 2px solid #f4f4f4;
      background: #fff;
      font-size: 32px;
      line-height: 43.65px;
      color: #000;
      text-align: center;
      margin-top: 2rem;
      &::placeholder {
        color: #bebebe;
      }
    }
  }

  &__block {
    margin-top: 50px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    .btn {
      width: 408px;
      height: 80px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
