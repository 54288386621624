import { createRouter, createWebHistory } from "vue-router";
import Main from "./pages/Main";
import Welcome from "./pages/Welcome";
import Products from "./pages/Products";
import OrderDetailed from "./pages/OrderDetailed";
import Payment from "./pages/Payment";
import NotWorking from "./pages/NotWorking";
import СatalogSets from "./pages/СatalogSets";

const routes = [
  { path: "/", name: "t-main", component: Main },
  { path: "/welcome", name: "t-welcome", component: Welcome },
  { path: "/products", name: "t-dishes", component: Products },
  { path: "/cart", name: "t-cart", component: OrderDetailed },
  { path: "/payment", name: "t-payment", component: Payment },
  { path: "/not-working", name: "t-not-working", component: NotWorking },
  { path: "/fast-sets", name: "catalog-sets", component: СatalogSets },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
