<template>
  <div class="wrapper">
    <router-view />
  </div>
</template>

<script>
import axios from "axios";
//import { api } from "@/api/api";
export default {
  name: "App",
  created() {
    axios
      .get("https://admin.tcode.online/api/projects/tubatay/shops/")
      .then((response) => {
        this.name = response.data.name;
        this.favicon = response.data.favicon;
        document.title = `${this.name}`;
        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = `${this.favicon}`;
        document.getElementsByTagName("head")[0].appendChild(link);
      });
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/style";
#app {
  overflow: hidden;
  height: 100vh;
}
</style>
